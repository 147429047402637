.pds-submenu {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;

  @media screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__card {
    border-radius: 2px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    padding: 12px;

    &:hover {
      box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
    }

    &__title {
      color: var(--color-yellow-500);
      margin-bottom: 12px;
    }

    &__subtitle {
      color: var(--color-gray-100);
      font-size: 1.4rem;
      font-weight: 400;
    }
  }
}
