.pds-form-control {
  background-color: #ffffff;
  border: 1px solid var(--g6, #ced4da);
  border-radius: 4px;
  color: var(--color-gray-500);
  font-family: var(--font-default);
  height: 48px;
  outline: none;
  padding: 0 12px;
  width: 100%;

  &::placeholder {
    color: var(--color-gray-100);
  }

  &:disabled {
    background-color: #f4f4f4;
    color: var(--color-gray-500);
    opacity: 1;
  }
}

select.pds-form-control {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z"/></svg>');
  background-position-x: 98%;
  background-position-y: 14px;
  background-repeat: no-repeat;
}
