.pds-button {
  text-decoration: none;
  display: inline-flex;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 8px;
  background: transparent;
  border: 1px solid transparent;
  font-family: var(--font-default);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  transition: all 0.3s linear;
  cursor: pointer;
  min-width: 120px;

  &:disabled {
    background: var(--color-secondary-50) !important;
    border-color: var(--color-secondary-50) !important;
    color: var(--color-secondary-200) !important;
    cursor: not-allowed !important;
  }

  .icon {
    font-size: 18px;
  }
}

.pds-button.pds-button-default {
  background: var(--color-primary-500);
  color: var(--color-secondary-500);
  border-color: var(--color-primary-200);

  &:hover {
    border-color: var(--color-primary-200);
    background: var(--color-primary-200);
  }
}

.pds-button.pds-button-primary {
  background: var(--color-white);
  color: var(--color-primary-500);
  border-color: var(--color-primary-500);

  &:hover {
    background: var(--color-primary-50);
    color: var(--color-primary-800);
    border-color: var(--color-primary-800);
  }
}

.pds-button.pds-button-secondary {
  background: var(--color-secondary-500);
  color: var(--color-white);

  &:hover {
    background: var(--color-secondary-300);
  }
}

.pds-button.pds-button-terciary {
  background: var(--color-white);
  color: var(--color-secondary-300);
  border-color: var(--color-secondary-300);

  &:hover {
    background: var(--color-secondary-50);
    border-color: var(--color-secondary-300);
    color: var(--color-secondary-500);
  }
}

.pds-button.pds-button-icon {
  display: inline-flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  padding: 0;
  width: 32px;
  min-width: auto;
  background: var(--color-white);
  color: var(--color-secondary-500);
  border-color: var(--color-secondary-300);
}
