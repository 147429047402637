:root {
  --color-white: #ffffff;
  --color-off-white: #fffdfa;
  --color-border: #91919440;

  --color-blue-500: #333338;

  --color-primary-900: #4a2f0d;
  --color-primary-800: #875617;
  --color-primary-700: #ae6f1e;
  --color-primary-600: #ca8223;
  --color-primary-500: #f59d2a;
  --color-primary-400: #fba93e;
  --color-primary-300: #feb04b;
  --color-primary-200: #fad29d;
  --color-primary-100: #ffcc81;
  --color-primary-50: #fef5ea;
  --color-primary-000: #fef5ea;

  --color-secondary-50: #ebebeb;
  --color-secondary-200: #a1a1a3;
  --color-secondary-300: #76767a;
  --color-secondary-500: #333338;

  --color-gray-100: #858381;
  --color-gray-200: #626266;
  --color-gray-500: #1d1a16;

  --font-headers: 'Montserrat', sans-serif;
  --font-default: 'Roboto', sans-serif;

  // white spaces
  --space-0: 0;
  --space-1: 2px;
  --space-2: 4px;
  --space-3: 8px;
  --space-4: 16px;
  --space-5: 32px;

  // radius
  --radius-0: 0;
  --radius-1: 2px;
  --radius-2: 4px;
  --radius-3: 8px;
  --radius-4: 16px;
  --radius-5: 32px;

  --color-yellow-50: #fef5ea;
  --color-yellow-500: #f59d2a;
  --color-yellow-700: #ae6f1e;

  --color-blue-50: #ebebeb;
  --color-blue-100: #c0c0c1;
  --color-blue-500: #333338;
}
