.pds-chip {
  display: inline-flex;
  height: 24px;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  text-align: center;
  font-family: var(--font-default);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 18px */
  text-transform: capitalize;
  text-wrap: nowrap;

  .icon {
    font-size: 16px;
  }

  &[ng-reflect-icon] {
    cursor: pointer;
  }
}

.pds-chip.pds-chip-default {
  background: var(--color-secondary-50);
  color: var(--color-secondary-500);
}

.pds-chip.pds-chip-primary {
  background: var(--color-primary-200);
  color: var(--color-secondary-500);
}

.pds-chip.pds-chip-secondary {
  background: var(--color-primary-700);
  color: var(--color-white);
}

.pds-chip.pds-chip-terciary {
  background: var(--color-secondary-500);
  color: var(--color-white);
}
