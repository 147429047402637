.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-12 {
  margin-top: 16px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.ml-24 {
  margin-left: 24px;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gap-2 {
  gap: var(--space-2);
}

.flex-column {
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.gap-4 {
  gap: 4px;
}

.wrap {
  flex-wrap: wrap;
}

.text-truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 120px;
}

.spin {
  animation: spin 4s linear infinite;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.justify-content-center {
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
}
