.pds-chip-link {
  font-weight: 700;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 12px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  display: inline-flex;
  text-decoration: none;

  .icon {
    font-size: 12px;
  }
}

.pds-chip-link-default {
  border-color: var(--color-primary-500);
  background-color: var(--color-primary-500);
  color: #262626;

  &.--is-outlined {
    background-color: transparent;
    color: var(--color-primary-500);
  }
}

.pds-chip-link-secondary {
  border-color: #262626;
  background-color: #262626;
  color: #f4f4f4;

  &.--is-outlined {
    background-color: transparent;
    color: #262626;
  }
}

.pds-chip-link-terciary {
  border-color: var(--color-blue-50);
  background-color: var(--color-blue-50);
  color: var(--color-blue-500);

  &.--is-outlined {
    background-color: transparent;
    color: var(--color-blue-500);
  }
}
